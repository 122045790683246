@import "~react-image-gallery/styles/css/image-gallery.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.sidebar-wrapper {
  transition: width 0.3s;
}

.sidebar-header {
  height: 64px;
  display: flex;
  background-color: #3a3a3a;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  color: rgb(255, 255, 255);
}
.css-16jesut >.ps-menu-button:hover {
  background-color: #49494a !important;
}

.rotate {
  transition: transform 0.5s ease-in-out;
  transform: rotate(360deg);
}

::-webkit-scrollbar {
  width: 8px; 
}


::-webkit-scrollbar-track {
  background-color: #afafaf; /* Change track color */
}


::-webkit-scrollbar-thumb {
  background-color: #000000; /* Change thumb color */
  border-radius: 8px; /* Adjust border-radius for a rounded thumb */
}

/* Scrollbar Thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #233459; /* Change thumb color on hover */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.mytable th,
.mytable td,
.mytable tr {
  border: 1px solid black;
}

.background-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.cont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 20px;
  background-color: rgba(32, 4, 4, 0.486);
  border-radius: 10px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
}

.logo {
    margin-bottom: 20px;
}

.cont img {
    width: 100px;
    display: block;
    margin: 0 auto;
}
.chartshape{
  border-radius: 10%;
}

@keyframes color-wave {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bg-gradient {
  background: linear-gradient(270deg, #f4bcc1, #ecdac2, #cbe3f7, #cef1d0);
  background-size: 800% 800%;
}

.animate-color-wave {
  animation: color-wave 15s ease infinite;
}
.regbg{
  background-image: url("../public/regbg.jpg");
  background-size:cover ;
}
.box-shadow:hover{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  transition: 0.6s;
}
.box-shadow{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  transition: 0.6s;
}
@media (max-width: 600px) {
  .flex-wrap-xs {
    flex-wrap: wrap;
  }
}
.icons-set{
  justify-content: center;
  display: flex;
  align-items: center;
}
.swiper-wrapper {
  display: flex !important;
}